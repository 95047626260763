.search-top-container {
  background-color: hsl(223, 40%, 21%);
}

.search-top-container p {
  margin: 0;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.3);
  text-align: left
}

.ais-SearchBox-input {
  background-color: #202c4b;
  border-width: 0;
  outline-width: 0;
  padding-left: 40px;
  color: white;
  font-size: 38px;
}

@media(max-width: 767px) {
  .ais-SearchBox-input {
    font-size: 28px;
  }
}

.ais-SearchBox-input::placeholder {
  color: #7c8497;
}

.ais-SearchBox-resetIcon {
  display: none;
  outline-width: 0;
}

.ais-SearchBox-submitIcon {
  width: 30px;
  height: 30px;
}

.ais-SearchBox-submit {
  outline-width: 0;
}

.ais-SearchBox-submit path {
  fill: white;
}

.profile-results-container .ais-Hits-item {
  border: 0;
  width: calc(50% - 1rem);
  box-shadow: 0 0 0 0;
}

@media(max-width: 991px) {
  .ais-Hits-list {
    flex-direction: column;
  }
  .profile-results-container .ais-Hits-item {
    width: 100%;
  }
}

.team-results-container .ais-Hits-item {
  border: 0;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 0 0 0;
}

.team-result-container {
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(15, 16, 37, 0.04);
  border: solid 1px rgba(255, 255, 255, 0.04);
  background-color: #ffffff;
  height: 100px;
  width: 100%;
}

.profile-result-container {
  height: 100px;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(15, 16, 37, 0.04);
  border: solid 1px rgba(255, 255, 255, 0.04);
  background-color: #ffffff;
}

.profile-result-text {
  h5 {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    color: #242629;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    text-decoration-color: black;
  }

  p {
    font-size: 14px;
    margin: 0;
    color: #9898a2;
  }
}

.profile-result-picture {
  max-width: 48px;
  max-height: 48px;
  border-radius: 3px;
  border: solid 1px rgba(0, 0, 0, 0.07);
}

.all-results-container {
  max-width: 1248px;
}

@media (min-width: 1200px) {
  .profile-results-container {
    border-right: solid 1px rgba(151, 151, 151, 0.15);
  }
}

.all-results-container h4 {
  text-align: left;
  margin: 0;
  margin-top: 25px;
  margin-bottom: 20px;
  color: #242629;
  font-size: 18px;
  font-weight: 400;
  margin-left: 20px;

}

.team-results-container {
  max-width: 400px;
}

.post-results-container .ais-Hits-item {
  width: 100%;
  background-color: white;
  color: black;
  cursor: pointer;
  transition: all .2s ease-in-out;

}

.post-results-container .ais-Hits-item:hover {
  transform: scale(1.03);
}

.search-post-title.ais-Highlight {
  font-weight: 600;
}

.team-result-container {
  a {
    text-decoration: none;
    font-size: 28px;
    font-weight: 400;
    color: #242629;
  }

  a:hover {
    text-decoration: underline;
    text-decoration-color: black;
  }

  p {
    margin: 0;
    font-size: 16px;
    color: rgba(36, 38, 41, 0.4);
  }
}
