.post-button {
  float: right;
  font-size: 12px;
  width: 60px;
  height: 30px;
  margin-top: -15px;
}

.comment-content {
  font-size: 16px;
  margin: 8px 0;
}

.post-author-photo {
  width: 64px;
  height: 64px;
  border-radius: 100px;
}

.post-response {
  width: 100%;
  margin-left: auto;
}

.post-clickable {
  font-size: 12px;
  font-weight: 200;
  cursor: pointer;
  color: #6d6d6d;
}

.post-clickable:hover {
  text-decoration: underline;
}

.post-author-card {
  border-radius: 7px;
  background-color: #ffffff;
  padding: 5px;
}

.post-author-role {
  color: rgba(0, 0, 0, 0.6);
}

.comment-header {
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  margin-bottom: 7px;
}

.post-author-link {
  color: #1ca1ff;
}

.post-author-link:hover {
  text-decoration: none;
}

.post-container {
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(5, 6, 19, 0.06), 0 2px 2px 0 rgba(6, 7, 16, 0.03);
}

.post-announcement {
  color: #000000d1;
  height: 30px;
  width: 100%;
  font-size: 14px;
  margin-top: -20px;
  background-color: #87d87da6;
  margin-bottom: 20px;
  border-radius: 20px;
}

.post-content {
  width: 100%;
}

.post-vote-widget {
  font-size: 14px;
  font-weight: bold;
}