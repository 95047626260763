.team-top-container {
  background-color: #202c4b;
  width: 100%;
}

@media (max-width: 767px) {
  .team-top-second-container {
    width: 100%;
  }
}

.team-contact-container {
  width: 100%;
  max-width: 400px;
  background-color: #f0f2f5;
  min-height: 250px;
  border-radius: 4px;
}

.team-contact-header {
  height: 56px;
}

.team-contact-content {
  background-color: #ffffff;
}

.team-contact-info {
  width: 100%;
}

.team-contact-info span {
  font-size: 14px;
  font-weight: 500;
  color: #9898a2;
}

.team-contact-info a {
  font-size: 14px;
  font-weight: 500;
  color: #0090ff;
  text-decoration: none;
  cursor: pointer;
}

.team-contact-info a:hover {
  text-decoration: underline;
  text-decoration-color: #0090ff;
}

.team-contact-person {
  width: 100%;
  height: 90px;
}

.team-contact-person-info {
  height: 100%;
}

.team-contact-person-info h5 {
  font-size: 16px;
  font-weight: 500;
  color: #242629;
}

.team-contact-person-info a {
  text-decoration: none;
}

.team-contact-person-info a:hover {
  text-decoration: underline;
  text-decoration-color: black;
}

.team-contact-person-info p {
  font-size: 14px;
  color: rgba(36, 38, 41, 0.51);
  margin: 0;
}

.team-contact-person-image {
  max-width: 50px;
  max-height: 50px;
  border-radius: 6px;
  border: solid 1px rgba(255, 255, 255, 0.06);
}

.team-info-container {
  max-width: 380px;
  line-height: 38px;
}

.team-info-header {
  font-size: 38px;
  color: #ffffff;
}

.team-info-description {
  font-size: 20px;
  color: rgba(255,255,255,0.4);
  text-align: start;
}

.team-resource-container {
  min-height: 250px;
  width: 100%;
  max-width: 400px;
  border-radius: 4px;
  background-color: rgba(40, 53, 84, 0.8);
}

@media (min-width: 768px) {
  .team-resource-container {
    width: 400px;
  }
}

.team-resource-header {
  font-size: 15px;
  height: 50px;
  color: #ffffff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.team-resource-link {
  height: 50px;
  font-size: 14px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}
.team-resource-link a {
  text-decoration: none;
  color: #ffffff;
  width: 100%;
}

.team-resource-link p:hover {
  text-decoration: underline;
  text-decoration-color: white;
}

.team-resource-link i {
  color: #ffffff;
  font-size: 10px;
}

.team-edit-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.icon-delete {
  cursor: pointer;
}

.add-resources-button {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  color: rgba(255,255,255,0.5);
  width: 150px;
  cursor: pointer;
  height: 50px;
}

.add-resources-button:hover {
  color: white;
}

.point-of-contact-label {
  color: #888888;
  position: absolute;
  font-size: 13px;
}
