.page-top-content {
  .page-title {
    font-size: 36px;
  }
}

.react-datepicker__input-container {
  width: 100%;
}

.onboarding-date-picker {
  height: 32px;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.3), inset 0 1px 2px rgba(67, 90, 111, 0.14);
  border: none;
  width: 100%;
  padding-left: 10px;
  color: #425A70;
  font-size: 14px;
  font-weight: 400;
}

div[data-evergreen-toaster-container] svg {
  vertical-align: top;
}

@media(max-width: 950px) {
  .save-top {
    display: none !important;
  }
}