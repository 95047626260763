.resources-link a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
}

.resources-link a:hover {
  text-decoration: none;
}

.homepage-content-wrapper {
  max-width: 1200px;
}
