.teams-subtitle {
  font-size: 21px;
  color: #6f727d;
  max-width: 375px;
}

.team-container {
  min-height: 138px;
  max-width: 600px;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(15, 16, 37, 0.04);
  border: solid 1px rgba(255, 255, 255, 0.04);
  background-color: #ffffff;
}

.team-text-container {
  overflow-wrap: break-word;

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    text-decoration-color: black;
  }

  h5 {
    font-size: 28px;
    font-weight: 400;
    margin: 0;
    color: #242629;
  }

  p {
    font-size: 16px;
    color: rgba(36, 38, 41, 0.4);
  }
}

@media (max-width: 414px) {
  .team-text-container {
    max-width: 60%;
  }
}

.team-count-circle {
  width: 38px;
  height: 38px;
  border-radius: 26px;
  border: solid 2px #ffffff;
  background-color: #0090ff;
  color: white;
}

.team-picture-circle {
  margin-left: -10px;
}
