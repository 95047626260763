.new-hires-all-container {
  width: 100%;
  max-width: 1300px;
}

.new-hires-header {
  font-size: 18px;
  font-weight: 500;
  color: #242629;
}

.new-hire-container {
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 3px 7px 0 rgba(5, 6, 19, 0.06), 0 12px 24px 0 rgba(6, 7, 16, 0.03);
  background-color: #ffffff;
  border: solid 1px rgba(255, 255, 255, 0.03);
}

.new-hire-text-container {
  display: flex;
  flex-direction: column;
  
  h5 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #242629;
  }

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #9898a2;
  }
}

.new-hire-picture-container {
  img {
    max-width: 100%;
    max-height: 112px;
    border-radius: 4px;
  }
}

.new-hire-contact {
  width: 100%;
  height: 38px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 6px;
  box-shadow: 0px 1.5px 1px 0px #c2c2ca;
  border: solid 1px rgba(236, 236, 236, 1);
  background-color: rgba(255, 255, 255, 0.1);
  background-origin: border-box;
  background-clip: content-box, border-box;

  span {
    color: #242629;
    font-size: 15px;
    font-weight: 500;
  }

  img {
    width: 20px;
    height: 20px;
    margin-left: 12px;
    margin-right: 12px;
  }

  i {
    color: #c8c8cf;
    font-size: 12px;
  }
}

.new-hire-link:hover,
.new-hire-contact:hover {
  text-decoration: none;
}

.new-hire-reports {
  margin-top: auto;
  border-radius: 12px;
  background-color: rgba(28, 161, 255, 0.15);
  color: #1ca1ff;
  font-size: 14px;
  font-weight: 500;
  width: fit-content;
  padding: 3px 10px;
}