.bio-container {
  width: 100%;
  max-width: 300px;
  border-radius: 5px;
  z-index: 1;
}

.bio-image-container {
  overflow: hidden;
  max-height: 414px;
  border-radius: 7px;
  box-shadow: 0 3px 7px 0 rgba(5, 6, 19, 0.06), 0 12px 24px 0 rgba(6, 7, 16, 0.03);
}

.bio-image-container img {
  width: 300px;
  box-shadow: 0 2px 10px 0 rgba(14, 14, 33, 0.5);
  border-radius: 4px;
}

.bio-text-container {
  text-align: left;
  margin-left: 24px;
  margin-top: 18px;
  padding-bottom: 15px;
  margin-right: 24px;
  min-height: 200px;
}

.bio-text-container h5 {
  font-size: 26px;
  color: #1d1e20;
  font-weight: 500;
  text-align: left;
  margin: 0;
}

.bio-text-container p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
}

.bio-role-team-row p {
  margin-top: 3px;
}

.bio-role {
  margin-top: 3px;
  border-radius: 12px;
  background-color: rgba(28, 161, 255, 0.15);
  color: #1ca1ff;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 10px;
}

.bio-team {
  margin-top: 3px;
  border-radius: 12px;
  background-color: rgba(255, 136, 34, 0.15);
  color: #eb730c;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 10px;
}

.bio-team a {
  text-decoration: none;
  color: #eb730c;
}

.bio-team a:hover {
  text-decoration: underline;
  text-decoration-color: #eb730c;
}

.bio-contact {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.bio-contact a {
  color: '#1ca1ff';
  font-size: 14px;
}

.bio-contact a:hover {
  text-decoration: none;
}

.bio-contact span:hover {
  text-decoration: underline;
}

.bio-contact-icon {
  font-size: 18px;
  /*color: black;*/
  opacity: 0.6;
  margin-right: 5px; 
  margin-top: 2px;
}

.file-upload-container {
  margin-top: -39px;
  position: relative;
  background-color: rgba(0,0,0,0.8);
  color: white;
}

.file-upload-label {
  padding-top: 8px;
  padding-bottom: 8px;
  display: block;
  height: 100%;
  width: 100%;
  cursor: pointer;
  margin-bottom: 0;
}

.profile-image-uploader {
  height: 414px;
}

.profile-inactive {
  float: right;
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  background-color: #ff0000a3;
  height: 20px;
  width: 100%;
  border-radius: 7px;
  margin-bottom: 10px;
  justify-content: center;
}