.objective-result-container,
.category-title-input,
.objective-name-input {
  label {
    display: none;
  }
}
.resource-header,
.results-header {
  font-size: 14px;
}

.delete-objective-icon {
  font-size: 16px;
  margin-top: 14px;
}

.validation-indicator {
  margin-top: 12px;
  color: #f47c7c;
}
