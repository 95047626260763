.top-bar-search-container {
  border-radius: 4px;
  border: solid 1px rgba(255, 255, 255, 0.03);
  background-color: rgba(255, 255, 255, 0.04);
  text-align: left;
  margin: auto;
  width: 100%;
  max-width: 413px;
  position: relative;

  .top-bar-search {
    height: 40px;
    width: 100%;
    outline-width: 0;
    color: white;
    font-size: 14px;
    background: transparent;
    border: none;
    padding-left: 35px;
  }
  .top-bar-search::placeholder {
    color: #7c8497;
  }
  .search-icon {
    color: white;
    position: absolute;
    margin: 12px 0 0 12px;
  }
}
// on medium devices+ the search bar should be smaller
@media(min-width: 900px) {
  .top-bar-search-container {
    border-radius: 16px;
    .top-bar-search {
      border-radius: 16px;
      height: 31px;
      color: #969dac;
      padding-left: 36px;
    }
    .search-icon {
      margin: 8px 0 0 13px;
    }
  }
}

.homepage-search-container {
  font-size: 30px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  border-radius: 12px;
  border: 1px solid white;
  background-color: #ffffff0d;
  text-align: left;
  margin: auto;
  position: relative;
  max-width: 850px;

  .homepage-search {
    height: 50px;
    width: 100%;
    max-width: 850px;
    outline-width: 0;
    font-size: 30px;
    background: transparent;
    border: none;
    outline: none;
    color: white;
    padding-left: 15px;
  }
  .homepage-search::placeholder {
    font-size: 30px;
    color: #969dac;
  }
  
  .homepage-search:focus {
    outline: 0 !important; 
  }

  .search-icon {
    color: white;
    font-size: 20px;
    margin-right: 20px;
  }
}


// on medium devices- the search bar should be smaller
@media(max-width: 900px) {
  .homepage-search-container {
    width: 90%;
  }
}