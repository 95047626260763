.channels-container,
.announcements-container {
  height: fit-content;
}

.announcements-header {
  height: 65px;
}

.announcement-preview-title {
  font-size: 18px;
  font-weight: bold;
}

@media(min-width: 1200px) {
  .announcements-container {
    min-width: 400px;
  }
  .channels-container {
    min-width: 200px;
  }
}


.announcements-section {
  width: 100%;
  max-width: 600px;
  min-height: 524px;
  display: flex;
  align-items: flex-start;
}

.channels-container,
.announcements-header,
.announcements-row {
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 3px 7px 0 rgba(5, 6, 19, 0.06), 0 12px 24px 0 rgba(6, 7, 16, 0.03);
}

.channels-header {
  border-bottom: 1px solid #80808038;
  height: 65px;
}

@media(max-width: 992px) {
  .announcements-section {
    width: 90%;
    padding-left: 20px;
  }
}

@media(min-width: 992px) {
  .announcements-section {
    padding-right: 25px;
    padding-left: 17px;
  }
}

.show-more-button {
  white-space: nowrap;
}

.post-preview-tag {
  font-size: 14px;
  border-radius: 20px;
  font-weight: bold;
  height: 18px;

  i {
    font-size: 8px;
  }
  span {
    font-size: 10px;
    font-weight: bold;
  }
}

.post-preview-announcement-tag {
  color: rgba(255, 128, 0, 1);
  background-color: rgba(255, 128, 0, 0.35);
  max-width: 115px;
}

.post-preview-pinned-tag {
  color: rgba(0, 102, 255, 1);
  background-color: rgba(0, 102, 255, 0.25);
  max-width: 115px;
}

.post-title {
  color: black;
}

.post-author:hover {
 color: #115f96;
}

.post-title-announcement {
  color: #27b062;
}

.post-title-announcement:hover {
  color: #187741;
}

.post-footer {
  font-size: 14px;
}

.tag {
  font-size: 12px;
  background: lightgrey;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.feedback-footer {
  width: 100%;
  min-height: 40px;
  margin-top: 20px;
  color: #808080c4 !important;
  border-radius: 2px;
  text-decoration-color: #808080c4 !important;
}

@media(max-width: 500px) {
  .tag {
    display: none;
  }
}

@media(max-width: 550px) {
  .announcement-helper {
    display: none !important;
  }
}

@media(max-width: 450px) {
  .sig-helper {
    display: none !important;
  }
}