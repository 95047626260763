.category-resource-container,
.category-title-input {
  label {
    display: none;
  }
}

.resource-header {
  font-size: 14px;
}

.delete-category-icon {
  font-size: 16px;
  margin-top: 14px;
}

.validation-indicator {
  margin-top: 12px;
  color: #f47c7c;
}
