.people-selector .ais-Hits-item {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
  z-index: 200;
}

.search-box:focus::placeholder {
  color: black;
  opacity: 0.4;
}

.search-box::placeholder {
  color: black !important;
  opacity: 1;
}

.search-box {
  margin-bottom: -10px;
  width: 100%
}

.clear-button {
  margin-top: 30px;
  margin-left: 10px;
  color: #da0000;
  cursor: pointer;
}

.people-selector .ais-Hits {
  margin-top: -20px;
  margin-right: 15px;
  width: 90%;
  z-index: 200;
  position: absolute;
  background-color: white;
}

.people-selector .ais-Hits-list {
  border: 1px solid  #d8dadc;
  margin-top: 0px;
  margin-left: 0px;
}

.people-selector .ais-Hits-list:empty {
  border: none;
} 

.profile-result {
  cursor: pointer;
  padding: 5px;
}

.profile-result :hover {
  background-color: #d8dadc;
}