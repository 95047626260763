$navItemColor: #7c8497;

.nav-container {
  nav {
    .nav-item {
      .nav-link {
        text-decoration: none;
        font-size: 14px;
        color: $navItemColor !important;
        margin: 0 10px;
      }
      .nav-link:hover {
        color: white;
      }
    }
    .nav-item.active .nav-link {
      color: white !important;
    }
  }
}

.top-bar-profile-circle {
  width: 32px;
  height: 32px;
  border-radius: 26px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(255, 255, 255, 0.1);
}


.mobile-search-container {
  width: 100%;
}

.resource-link,
.popover-nav > span {
  font-size: 14px;
  color: $navItemColor;
}

.resource-link,
.resource-link:hover {
  color: #242629;
}
