.profile-content-container {
  max-width: 900px;
}

.profile-background-top {
  background-color: #202c4b;
  height: 250px;
  width: 100%;
}

.profile-calendar {
  border-radius: 4px;
}

.profile-tab-container {
  width: 100%;
  min-height: 580px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.profile-tab-icon {
  color: #000;
  opacity: 0.6;
}

.profile-tab-map-container {
  overflow: hidden;
  display: block;
  box-shadow: 0 3px 7px 0 rgba(5, 6, 19, 0.06), 0 12px 24px 0 rgba(6, 7, 16, 0.03);
  border-radius: 5px;
}

.profile-tab-map-container img {
  width: 100%;
  display: block;
}

.profile-map-text-bubble {
  background-color: #ffffff;
  border-radius: 15px;
  color: #1c1a1a;
  height: 25px;
  line-height: 18px;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  margin: -40px 15px 0px 15px;
  z-index: 1;
}

.profile-tab-header-container {
  height: 250px;
  background-color: rgba(40, 53, 84, 0.8);
}

.profile-tab-header {
  font-size: 15px;
  font-weight: 500;
  color: #000;
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: 14px 0;
  justify-content: center;
  border: solid 1px #2c3857;
}

.profile-tab-active {
  border-bottom: solid 2px #1ca1ff;
}

.profile-tab-content {
  a, span {
    color: #000;
  }
  border-width: 1px;
}

.profile-tab-content-row {
  margin: 16px 28px 32px 23px;  
  display: flex;
  flex-direction: row;
}

.profile-tab-content-row p {
  margin: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
}

.profile-tab-right-content {
  margin: 0;
  font-size: 15px;
  color: #ffffff;
  text-align: right;
  margin-left: auto;
  font-weight: 500;
}

.profile-tab-content-row a {
  text-decoration: none;
}

.profile-tab-content-row a:hover {
  text-decoration: underline;
  text-decoration-color: #000;
}

.profile-group-bubbles-container {
  margin-left: auto;
  max-width: 275px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-flow: wrap;
  margin-top: -10px;
}

.profile-group-bubble {
  margin-left: 8px;
  margin-top: 12px;
  height: 28px;
  border-radius: 14px;
  color: #000;
  font-size: 14px;
  padding: 0 12px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: white;
  line-height: 16px;
}

.profile-group-bubble a {
  color: #000;
  text-decoration: none;
}

.profile-group-bubble a:hover {
  text-decoration: none;
  text-decoration-color: #000;
}

.group-more-bubble {
  color: #202c4b;
  background-color: #000;
}

.map-overlay-edit {
  color: #000;
  position: relative;
  background-color: rgba(0,0,0,0.5);
  z-index: 1;
  cursor: pointer;
  padding-top: 60px;
  height: 160px;
  margin-top: -160px;
}  

.profile-calendar-add-button {
  position: absolute;
  margin-top: 26px;
  margin-left: 295px;
  border: 1px rgba(255, 255, 255, 0.07) solid;
  height: 24px;
  width: 32px;
  color: #000;
  cursor: pointer;
}

.profile-calendar-add-button:hover {
  border: 1px solid rgba(255,255,255,0.7); 
}

.profile-dark-edit-button {
  border-radius: 3px;
  border: solid 1px rgba(255, 255, 255, 0.07);
  color: #000;
  height: 48px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 15px;
  margin-top: auto;
}

.profile-dark-edit-button:hover {
  border: 1px solid rgba(255,255,255,0.7); 
}

.profile-edit-button {
  width: 90%;
  max-width: 372px;
  height: 48px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(54, 54, 82, 0.12);
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(to bottom, #e5e5e9, #c2c2ca);
  border-image-slice: 1;
  background-image: rgba(255, 255, 255, 0.1), linear-gradient(to bottom, #e5e5e9, #c2c2ca);
  background-origin: border-box;
  background-clip: content-box, border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: auto;
  margin-bottom: 0px;
}

.color-desc {
  display: flex;
  flex-direction: row;
  color: rgba(255,255,255,0.4);
  font-size: 13px;
  align-items: center;
  margin-bottom: 20px;
}

.color-square {
  width: 20px;
  height: 20px;
  margin-right: 7px;
  margin-left: 20px;
  border-radius: 4px;
}

.pointer {
  width: 700px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #fff;
  color: black;
  min-width: 150px;
  margin-top: 15px;
  flex-basis: 150px;
  flex-grow: 1;
  flex-shrink: 1;
}

.pointer-next {
  margin-right: 27px;
}

.pointer:hover {
  text-decoration: none;
  color: black;
}

.pointer p {
  margin: 0;
  font-size: 10px;
  color: #7f7f8a;
  margin-left: 10px
}

.pointer span {
  font-size: 14px;
  margin-left: 10px;
}

.pointer-previous:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid #ebedf0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
}

.pointer-next:before {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 21px solid white;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
}

.current-pointer.pointer {
  background-color: #202c4b;
  color: #fff;
  width: 600px;
}
.current-pointer:before {
  border-left: 20px solid #202c4b !important;
}

@media (max-width: 700px) {
  .management-chain {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .ma {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .ma.fw {
    width: 90%;
  }

  .profile-tab-header-container {
    background-color: transparent;
    height: auto;
  }

  .bio-text-container {
    align-items: center;
    min-height: 0px;
  }

  .blt {
    color: black !important;
  }

  .bio-contact {
    align-items: center;
  }
}

.p-header {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
}

.p-margin {
  margin-left: 22px;
}