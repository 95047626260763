.events-section {
  width: 100%;
  max-width: 413px;
  right: 0;
  align-self: auto;
}

.events-header {
  height: 65px;
}

.events-container {
  width: 100%;
  max-width: 360px;
}

.white-container {
  background: white;
  border-radius: 2px;
  box-shadow: 0 3px 7px 0 rgba(5, 6, 19, 0.06), 0 12px 24px 0 rgba(6, 7, 16, 0.03);
}

.event-card-shadow {
  box-shadow: 0 2px 3px 0 rgba(5, 6, 19, 0.06), 0 0px 1px 0 rgba(6, 7, 16, 0.03)
}

.event-detail-content {
  width: 100%;
}

.event-detail-content span {
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.events-photo-icon {
  max-width: 50px;
  float: left;
  clear: left;
  align-self: center;
  border-radius: 50px;
  border: solid 1px rgba(255, 255, 255, 0.06);
}

.event-card-link {
  color: inherit;
}

.event-card-container {
  border-top: 1px solid #80808038;
  border-radius: 0;
  height: 95px;
}
